import PropTypes from "prop-types";
import React from "react";

import i18nContext from "../components/i18n/i18n.context";
import Layout from "../components/layout/Layout";
import Page from "../components/page/Page";
import Prices from "../components/prices/Prices";
import SEO from "../components/seo/Seo";

function TarifsPage({ location }) {
  return (
    <i18nContext.Provider value={{ locale: "fr" }}>
      <Layout>
        <SEO
          keywords={["Jawg", "JawgMaps", "Tarif"]}
          location={location}
          metaDescription="Consultez notre page de tarification pour en savoir plus sur les différents plans proposés par Jawg et lesquels sont les mieux adaptés à votre trafic."
          title="Tarifs"
        />
        <Page>
          <Prices />
        </Page>
      </Layout>
    </i18nContext.Provider>
  );
}

TarifsPage.propTypes = {
  location: PropTypes.object.isRequired, //eslint-disable-line react/forbid-prop-types
};

export default TarifsPage;
